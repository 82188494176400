import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Vid from "../media/videos/virtual_training.mp4"
import VidPoster from "../media/videos/virtual_training_poster.jpg"

// import components
import Layout from "../components/sections/layout"
import Seo from "../components/sections/seo"
import HeaderVideo from "../components/headerVideo"

// import templates
import BasicLayout from "../templates/basic-layout"

import styled from "styled-components"
const VideoContainer = styled.div`
  background-color: whitesmoke;
  line-height: 0;
  padding: 0;
  margin: 40px 0;
`

const VideoRatio = styled.div`
  position: relative;
  padding-top: 52.8%;
`

const IframeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const seo_description = `Virtual online training sessions in Hove & Brighton, which will enable you to stay fit. Group online training is available for the same price as an individual online personal training session.`
const seo_keywords = `Virtual sessions Hove, virtual sessions Hove & Brighton, virtual sessions Brighton, long term injury, Virtual online training sessions, fitness training, NHS approved, Virtual Personal Training Hove & Brighton, personal training, 1-on-1 workouts, affordable prices, over 60s club, Online, , personal training Hove, personal training Brighton, online personal training, Hove personal trainer, Best personal trainer Hove, Personal Trainer in Brighton, Personal Trainer in Hove, Personal Trainer Near Me, online personal trainer hove, online personal trainer brighton, PT Hove & Brighton`

const VirtualPersonalTraining = () => {
  const data = useStaticQuery(query)
  const { strapiVirtualPersonalTraining } = data
  const { title, description, SEO } = strapiVirtualPersonalTraining

  const {
    page_description = seo_description,
    page_keywords = seo_keywords,
    page_title = title,
  } = SEO
  return (
    <Layout>
      <Seo
        title={page_title}
        description={page_description}
        keywords={page_keywords}
      />
      <HeaderVideo src={Vid} poster={VidPoster} title={title} />
      <VideoContainer>
        <div className="container">
          <VideoRatio>
            <IframeContainer>
              <iframe
                width={"100%"}
                height={"100%"}
                src={"https://www.youtube.com/embed/7oKYEsT1css?rel=0"}
                title="Ty Paul Virtual Training"
                frameBorder="0"
                allow="accelerometer;"
                allowFullScreen
              ></iframe>
            </IframeContainer>
          </VideoRatio>
        </div>
      </VideoContainer>
      <BasicLayout
        pageName={title}
        description={description}
        rightImage={""}
        images={[]}
      />
    </Layout>
  )
}

const query = graphql`
  {
    strapiVirtualPersonalTraining {
      SEO {
        page_description
        page_keywords
        page_title
      }
      title
      description
    }
  }
`

export default VirtualPersonalTraining
